<template>
  <container-page>
    <bloc-simple class="flex-shrink-0 mb-3" :style="styleLeftBarLg">
      <left-bar></left-bar>
    </bloc-simple>
    <div class="flex-grow-1 mb-3">
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </div>
  </container-page>
</template>
<script>
import BlocSimple from "@/components/containers/ContainerBloc.vue";
import ContainerPage from "@/components/containers/ContainerPage.vue";
import { mapActions } from "vuex";
import screenWidth from "@/mixins/screenWidth";
import leftBar from "@/components/cse/LeftBar.vue";

export default {
  components: {
    ContainerPage,
    BlocSimple,
    leftBar,
  },
  mixins: [screenWidth],
  methods: {
    ...mapActions({}),
  },
  mounted() {},
  computed: {
    styleLeftBarLg() {
      if (this.w > 992) return "min-width:350px;max-height:100vh;overflow:auto;position:sticky;top:0;z-index:1020;";
      else return "max-height:50vh;overflow:auto;";
    },
  },
};
</script>
