<template>
  <div>
    <div class="p-3" :class="qwarkBorder">BDES CSE</div>
    <div v-background-3>
      <router-link
        :to="{ name: 'rubrique_1' }"
        class="d-flex align-items-center hover-item w-100 p-3 cursor-pointer"
        :class="[hoverClass, qwarkBorder]"
        :active-class="activeClass"
        tag="div"
      >
        <base-icon name="activity" width="24" height="24" class="m-0 mb-1 mr-2"></base-icon>
        <span>Orientations stratégiques</span>
      </router-link>
      <router-link
        :to="{ name: 'rubrique_2' }"
        class="d-flex align-items-center hover-item w-100 p-3 cursor-pointer"
        :class="[hoverClass, qwarkBorder]"
        :active-class="activeClass"
        tag="div"
      >
        <base-icon name="activity" width="24" height="24" class="m-0 mb-1 mr-2"></base-icon>
        <span>Situation économique et financière</span>
      </router-link>
      <router-link
        :to="{ name: 'rubrique_3' }"
        class="d-flex align-items-center hover-item w-100 p-3 cursor-pointer"
        :class="[hoverClass, qwarkBorder]"
        :active-class="activeClass"
        tag="div"
      >
        <base-icon name="activity" width="24" height="24" class="m-0 mb-1 mr-2"></base-icon>
        <span>Politique sociale, conditions de travail et l'emploi</span>
      </router-link>
      <router-link
        :to="{ name: 'rubrique_4' }"
        class="d-flex align-items-center hover-item w-100 p-3 cursor-pointer"
        :class="[hoverClass, qwarkBorder]"
        :active-class="activeClass"
        tag="div"
      >
        <base-icon name="activity" width="24" height="24" class="m-0 mb-1 mr-2"></base-icon>
        <span>Informations complémentaires</span>
      </router-link>
    </div>
  </div>
</template>
<script>
import BaseIcon from "@/components/bases/Icon.vue";

export default {
  components: { BaseIcon },
  methods: {},
  mounted() {},
  computed: {
    activeClass: function () {
      if (!this.$store.getters["colors/darkMode"]) {
        return "active-item-light text-danger";
      } else {
        return "active-item-dark text-warning";
      }
    },
    hoverClass: function () {
      if (!this.$store.getters["colors/darkMode"]) {
        return "cursor-hover-light";
      } else {
        return "cursor-hover-dark";
      }
    },
    qwarkBorder: function () {
      if (!this.$store.getters["colors/darkMode"]) {
        return "qwark-border-light";
      } else {
        return "qwark-border-dark";
      }
    },
  },
};
</script>
