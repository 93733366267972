<template>
  <div class="rounded shadow-sm" :class="backgroundClass" :style="{ backgroundColor: color }">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "ContainerBloc",
  computed: {
    backgroundClass: function() {
      if (!this.$store.getters["colors/darkMode"]) {
        return "";
      } else {
        return "text-white ";
      }
    },
    color: function() {
      return this.$store.getters["colors/colors"].color2;
    }
  }
};
</script>
<style>
.qwark-block {
  max-height: calc(200vh - 76px);
  overflow: auto;
}
</style>
